import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as UserActions } from "store/ducks/user";
import { Creators as WalletBigCompanyActions } from "store/ducks/walletBigCompany";
import { Creators as PaymentProfileActions } from "store/ducks/bigcompany/paymentProfile";
import { Creators as CommercialSettingsActions } from "store/ducks/commercialSettings";
import { Creators as BigcompanyProfile } from "store/ducks/bigcompany/profile";
import { Creators as LevelsUsers } from "store/ducks/levelsUsers";
import { Creators as SubscriptionSummaryAction } from "store/ducks/subscriptionSummary";
import { Creators as BigcompanySettingsActions } from "store/ducks/bigcompany/settings";
import { Creators as SecurityActions } from "store/ducks/bigcompany/security";
import { Creators as CurrentUserActions } from "store/ducks/currentUser";

export function* getUser(action) {
  try {
    const headers = !!action?.payload?.data
      ? { headers: { Authorization: `Bearer ${action.payload.data}` } }
      : {};
    const { data } = yield call(MainService.get, "v2/user", headers);

    const user_is_admin_and_is_owner_of_billing =
      ["adm"].includes(data?.account_type) &&
      !data?.big_company?.separated_billing;

    if (user_is_admin_and_is_owner_of_billing) {
      yield put(
        PaymentProfileActions.request({
          data: data?.big_company?.pk,
          user: "bigcompany",
        })
      );
    }

    if (
      data?.account_type !== "supervisor" &&
      !data?.big_company?.separated_billing
    ) {
      yield put(WalletBigCompanyActions.setWallet(data?.big_company?.pk));
    }

    yield put(
      CommercialSettingsActions.request({
        pk: data?.big_company?.pk,
        user: "bigcompany",
        required: user_is_admin_and_is_owner_of_billing,
      })
    );

    if (data?.account_type !== "supervisor") {
      yield put(LevelsUsers.setLevels({ pk: data?.big_company?.pk }));
      yield put(SecurityActions.request({ id: data?.big_company?.pk }));
    }
    yield put(BigcompanySettingsActions.request({ pk: data?.big_company?.pk }));

    yield put(BigcompanyProfile.request({ pk: data?.big_company?.pk }));

    yield put(UserActions.getUserSuccess(data));

    yield put(
      SubscriptionSummaryAction.request({
        user: "bigcompany",
        userId: data?.big_company?.pk,
      })
    );

    yield put(
      CurrentUserActions.setUser({
        pk: data?.big_company?.pk,
        user: "bigcompany",
      })
    );
  } catch (error) {
    if (error?.response && error?.response?.status !== 401) {
      yield put(UserActions.getUserFailure(error.message));
    }
  }
}
