const INITIAL_STATE = {
  loading: false,
  loaded: false,
  error: "",
  data: [],
};

/**
 * TYPES
 */

export const Types = {
  REQUEST: "security-request",
  SUCCESS: "security-success",
  FAILURE: "security-failure",
};
/**
 * REDUCER
 */
export function security(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.REQUEST:
      return { ...state, loading: true, error: "" };
    case Types.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        loaded: true,
      };
    case Types.FAILURE:
      return { ...state, loading: false, error: action.payload.data };
    default:
      return state;
  }
}

/**
 * ACTIONS
 */
export const Creators = {
  request: ({ id }) => ({
    type: Types.REQUEST,
    payload: { id },
  }),
  success: (data) => ({
    type: Types.SUCCESS,
    payload: { data },
  }),
  failure: (data) => ({
    type: Types.FAILURE,
    payload: { data },
  }),
};
