import mainService from "services/mainService";

/**
 * @description Busca a lista de usuários inativos
 * @param {Object} params
 * @param {number} params.bigcompanyId
 * @param {number} params.page
 * @param {number} params.pageSize
 * @returns {Promise}
 * @example getUsersInactive({ bigcompanyId: 1, page: 1, pageSize: 10 });
 */
export const getUsersInactive = async ({ bigcompanyId, page, pageSize }) => {
  // TODO
  return mainService.get(
    `v2/bigcompany/${bigcompanyId}/user_access_levels/list_inactive?page=${page}&page_size=${pageSize}`
  );
};

/**
 * @description Deleta usuários inativos
 * @param {Object} params
 * @param {number} params.bigcompanyId
 * @param {Array} params.users
 * @returns {Promise}
 * @example deleteUsersInactive({ bigcompanyId: 1, users=[1] });
 */
export const deleteUsersInactive = async ({ bigcompanyId, users = [] }) => {
  // TODO
  return mainService.delete(
    `v2/bigcompany/${bigcompanyId}/user_access_levels/detach`,
    {
      data: {
        users,
      },
    }
  );
};

/**
 * @description Busca o nivel de segurança dos usuários
 * @param {Object} params
 * @param {number} params.bigcompanyId
 * @param {number} params.page
 * @param {number} params.pageSize
 * @param {string} params.password_level
 * @returns {Promise}
 * @example getLevelSecurityUsers({ bigcompanyId: 1, page: 1, pageSize: 10, password_level: "strong" });
 */
export const getLevelSecurityUsers = async ({
  bigcompanyId,
  page,
  pageSize,
  password_level,
}) => {
  // TODO
  return mainService.get(
    `v2/bigcompany/${bigcompanyId}/user_access_levels/password_levels?page=${page}&page_size=${pageSize}&password_level=${password_level}`
  );
};

/**
 * @description Busca os usuário que tem 2fa ativa
 * @param {Object} params
 * @param {number} params.bigcompanyId
 * @param {number} params.page
 * @param {number} params.pageSize
 * @param {string} param.two_fa_status
 * @returns {Promise}
 * @example getUsersAuthActive({ bigcompanyId: 1, page: 1, pageSize: 10, two_fa_status: true });
 */
export const getUsersAuthActive = async ({
  bigcompanyId,
  page,
  pageSize,
  two_fa_status,
}) => {
  // TODO
  return mainService.get(
    `v2/bigcompany/${bigcompanyId}/user_access_levels/2fa_list?page=${page}&page_size=${pageSize}&2fa_status=${two_fa_status}`
  );
};
