import { call, put } from "redux-saga/effects";
import MainService from "services/mainService";

import { Creators as BigcompanySecurityActions } from "store/ducks/bigcompany/security";

export function* getBigcompanySecurity(action) {
  try {
    const { data } = yield call(
      MainService.get,
      `v2/bigcompany/${action.payload.id}/user_access_levels/overall_password_strength`
    );
    yield put(BigcompanySecurityActions.success(data));
  } catch (error) {
    yield put(BigcompanySecurityActions.failure({ data: error.message }));
  }
}
